<p>generate-payments-window-lba works!</p>
<div #specificContainer class="iu-specific-window iu-specific-window-container" [attr.data-cy-specific-window-name]="name">
    <p-progressBar
      *ngIf="!isModal && isLoading"
      [mode]="'indeterminate'"
      [value]="0"
      [showValue]="false"
      [styleClass]="'loading-progressbar'"
    ></p-progressBar>
    <a *ngIf="vcrOptional.length > 0" class="settings" (click)="isSidebarOpen = !isSidebarOpen"><i class="fas fa-cog"></i></a>
    <p-scrollPanel [style]="{ width: '100%', height: '100%' }">
      <div class="p-col-12">
        <label [hidden]="title && title.length === 0" class="specific-title"
          ><b>{{ title }}</b></label
        >
        <div class="fields">
          <ng-template iuDynamicContainer></ng-template>
        </div>
      </div>
      <div class="iu-specific-btn-container" [ngClass]="[shouldAddPadding ? 'shouldAddPadding' : '']">
        <iu-button-ui
          *ngIf="gridViews && gridViews.length === 1 && hasInfoWindowGrid"
          class="iu-btn p-col-12 p-md-3 p-lg-2"
          (click)="$event.preventDefault(); $event.stopPropagation(); zoomOnLineSelected()"
          label="{{ 'contextmenu.zoom' | translate }}"
          icon="fa fa-search-plus"
          data-cy="form-button-zoom"
        >
        </iu-button-ui>
        <ng-template #vcrButtons></ng-template>
        <iu-button-ui
          *ngIf="isModal && gridViews && gridViews.length === 1 && hasInfoWindowGrid"
          class="p-col-12 p-md-3 p-lg-2"
          (click)="$event.preventDefault(); $event.stopPropagation(); setSelectedRowOnAutocomplete(); closeModalEmitter.emit()"
          label="{{ 'infodialog.ok' | translate }}"
          data-cy="form-button-validate"
        >
        </iu-button-ui>
        <iu-button-ui
          *ngIf="isModal"
          class="iu-btn btn-cancel p-col-12 p-md-3 p-lg-2"
          (click)="$event.preventDefault(); $event.stopPropagation(); closeModalEmitter.emit()"
          label="{{ 'process.cancel' | translate }}"
          icon="fa fa-ban"
        >
        </iu-button-ui>
      </div>
    </p-scrollPanel>
    <p-sidebar [(visible)]="isSidebarOpen" [baseZIndex]="6000" position="right" styleClass="p-sidebar-md optionalFilterPanel">
      <h1 class="iu-notification-center-title">
        {{ 'specificWindow.optionalFilters' | translate }}
      </h1>
      <div class="optionalFields">
        <ng-template #optional></ng-template>
      </div>
    </p-sidebar>
  </div>
  <ng-container *ngIf="displaySearch">
    <iu-modal-ui
      data-cy="search-panel"
      [attr.data-cy-columnName]="this.searchLinkedComponent.autoComplete.data.columnName"
      [title]="this.searchLinkedComponent.autoComplete.label"
      [hasCloseBtn]="true"
      (closeModalEmitter)="closeModalSearch()"
    >
      <div class="autocomplete-universal-filter">
        <iu-universal-filter-ui
          [tabId]="-1"
          [columns]="searchLinkedComponent.autoComplete.data.searchColumns"
          [gridTab]="gridTab"
        ></iu-universal-filter-ui>
      </div>
      <iu-grid-tab-infinity-scroll-ui
        #gridTab
        (searchEmitter)="
          this.searchLinkedComponent.setSearchSelectItem($event); displaySearch = false; searchPanelValidation = undefined
        "
        [data]="this.searchLinkedComponent.autoComplete.data"
        [container]="this"
        [isSearch]="true"
        [dataStored]="this.searchLinkedComponent.autoComplete.dataStored"
        [validation]="searchPanelValidation"
      ></iu-grid-tab-infinity-scroll-ui>
    </iu-modal-ui>
  </ng-container>
  <div [ngClass]="[!displayFormUI ? 'hidden' : '', 'specificModal']">
    <iu-modal-ui
      [title]="specificWindowTitle"
      [isModalDisplay]="displayFormUI"
      (closeModalEmitter)="updateModalDisplay({ key: 'displayFormUI', value: false, sourceComponent: this })"
      [angularStyle]="customFormModalBodyCss"
    >
      <ng-template #vcrSpecific></ng-template>
    </iu-modal-ui>
  </div>
  