import { DOCUMENT } from '@angular/common';
import { Component, HostListener, Inject, OnDestroy, OnInit } from '@angular/core';
import { AccountEditorWindowUiComponent } from '@iupics-components/specific/window/account-editor-window-ui/account-editor-window-ui.component';
import { AccountingViewerUiComponent } from '@iupics-components/specific/window/accounting-viewer-ui/accounting-viewer-ui.component';
import { AccountingViewerWindowUiComponent } from '@iupics-components/specific/window/accounting-viewer-window-ui/accounting-viewer-window-ui.component';
import { BpartnerBalanceComponent } from '@iupics-components/specific/window/bpartner-balance/bpartner-balance.component';
import { ChangelogWindowUIComponent } from '@iupics-components/specific/window/changelog-window-ui/changelog-window-ui.component';
import { CreateFromInvoiceWindowUiComponent } from '@iupics-components/specific/window/create-from-invoice-window-ui/create-from-invoice-window-ui.component';
import { CreateFromOpenInvoiceStWindowUiComponent } from '@iupics-components/specific/window/create-from-open-invoice-st-window-ui/create-from-open-invoice-st-window-ui.component';
import { CreateFromOpenInvoiceComponent } from '@iupics-components/specific/window/create-from-open-invoice/create-from-open-invoice.component';
import { CreateFromShipmentWindowUiComponent } from '@iupics-components/specific/window/create-from-shipment-window-ui/create-from-shipment-window-ui.component';
import { CreateFromStatementWindowUiComponent } from '@iupics-components/specific/window/create-from-statement-window-ui/create-from-statement-window-ui.component';
import { CruiseSituationUiComponent } from '@iupics-components/specific/window/cruise-situation-ui/cruise-situation-ui.component';
import { DeliveryModeSelectionUIComponent } from '@iupics-components/specific/window/delivery-mode-selection-ui/delivery-mode-selection-ui.component';
import { DirectPrintingDocumentsComponent } from '@iupics-components/specific/window/direct-printing-documents/direct-printing-documents.component';
import { FastCreateInvoiceVendorComponent } from '@iupics-components/specific/window/fast-create-invoice-vendor/fast-create-invoice-vendor.component';
import { FastCreateOrderComponent } from '@iupics-components/specific/window/fast-create-order/fast-create-order.component';
import { ImportFileLoaderWindowUiComponent } from '@iupics-components/specific/window/import-file-loader-window-ui/import-file-loader-window-ui.component';
import { InOutConfirmBatchComponent } from '@iupics-components/specific/window/inout-confirm-batch/inout-confirm-batch.component';
import { InvoiceMatchingWindowImplComponent } from '@iupics-components/specific/window/invoice-matching-window-impl/invoice-matching-window-impl.component';
import { LocationPanelComponent } from '@iupics-components/specific/window/location-panel/location-panel.component';
import { LoginasFormComponent } from '@iupics-components/specific/window/loginas-form/loginas-form.component';
import { OperationManagementUIComponent } from '@iupics-components/specific/window/operation-management-ui/operation-management-ui.component';
import { PartnerViewComponent } from '@iupics-components/specific/window/partner-view/partner-view.component';
import { PaymentAllocationWindowUiComponent } from '@iupics-components/specific/window/payment-allocation-window-ui/payment-allocation-window-ui.component';
import { PaymentSelectionWindowUiComponent } from '@iupics-components/specific/window/payment-selection-window-ui/payment-selection-window-ui.component';
import { PlanningWindowUiComponent } from '@iupics-components/specific/window/planning-window-ui/planning-window-ui.component';
import { PosUiComponent } from '@iupics-components/specific/window/pos-ui/pos-ui.component';
import { PriceHistoryComponent } from '@iupics-components/specific/window/price-history/price-history.component';
//#region Custo
import { PrintZoneComponent } from '@iupics-components/specific/window/print-zone/print-zone.component';
//#endregion Custo
import { ProcessInProgressWindowUiComponent } from '@iupics-components/specific/window/process-in-progress-window-ui/process-in-progress-window-ui.component';
import { ProductAttributeComponent } from '@iupics-components/specific/window/product-attribute/product-attribute.component';
import { ProductViewComponent } from '@iupics-components/specific/window/product-view/product-view.component';
import { RelatedProductsComponent } from '@iupics-components/specific/window/related-products/related-products.component';
import { SelectOrderComponent } from '@iupics-components/specific/window/select-order/select-order.component';
import { SpecificWindowUiComponent } from '@iupics-components/specific/window/specific-window-ui/specific-window-ui.component';
import { ThirdPartyAddressLabelUiComponent } from '@iupics-components/specific/window/third-party-address-label-ui/third-party-address-label-ui.component';
import { TreeMaintenanceUIComponent } from '@iupics-components/specific/window/tree-maintenance-ui/tree-maintenance-ui.component';
import { AutocompleteUiComponent } from '@iupics-components/standard/fields/autocomplete-ui/autocomplete-ui.component';
import { ButtonUiComponent } from '@iupics-components/standard/fields/button-ui/button-ui.component';
import { CalendarUiComponent } from '@iupics-components/standard/fields/calendar-ui/calendar-ui.component';
import { CommentUiComponent } from '@iupics-components/standard/fields/comment-ui/comment-ui.component';
import { InputFileUiComponent } from '@iupics-components/standard/fields/input-file-ui/input-file-ui.component';
import { InputImageUiComponent } from '@iupics-components/standard/fields/input-image-ui/input-image-ui.component';
import { InputLocationUiComponent } from '@iupics-components/standard/fields/input-location-ui/input-location-ui.component';
import { InputNumberUiComponent } from '@iupics-components/standard/fields/input-number-ui/input-number-ui.component';
import { InputProductAttributesUiComponent } from '@iupics-components/standard/fields/input-product-attributes-ui/input-product-attributes-ui.component';
import { InputSwitchUiComponent } from '@iupics-components/standard/fields/input-switch-ui/input-switch-ui.component';
import { InputTextUiComponent } from '@iupics-components/standard/fields/input-text-ui/input-text-ui.component';
import { InputTextareaUiComponent } from '@iupics-components/standard/fields/input-textarea-ui/input-textarea-ui.component';
import { InputTimeUiComponent } from '@iupics-components/standard/fields/input-time-ui/input-time-ui.component';
import { StepperUiComponent } from '@iupics-components/standard/fields/stepper-ui/stepper-ui.component';
import { GridViewUiComponent } from '@iupics-components/standard/grid/grid-view-ui/grid-view-ui.component';
import { AccordionUiComponent } from '@iupics-components/standard/layouts/accordion-ui/accordion-ui.component';
import { AdditionalInfoUiComponent } from '@iupics-components/standard/layouts/additional-info-ui/additional-info-ui.component';
import { EditTabUiComponent } from '@iupics-components/standard/layouts/edit-tab-ui/edit-tab-ui.component';
import { EditViewUiComponent } from '@iupics-components/standard/layouts/edit-view-ui/edit-view-ui.component';
import { GridUiComponent } from '@iupics-components/standard/layouts/grid-ui/grid-ui.component';
import { RowUiComponent } from '@iupics-components/standard/layouts/row-ui/row-ui.component';
import { MenuBarDetailUiComponent } from '@iupics-components/standard/menu/menu-bar-detail-ui/menu-bar-detail-ui.component';
import { ValuePreferencePanelComponent } from '@iupics-components/standard/value-preference-panel/value-preference-panel.component';
import { IupicsCookieService, LocalStorageIupics } from '@iupics-manager/managers/security-manager/cookies/iupics-cookie.service';
import { Global } from '@iupics-manager/models/global-var';
import { IupicsMessage } from '@iupics-manager/models/iupics-message';
import { UserAccount } from '@login-page/models/user-account.';
import { TranslateService } from '@ngx-translate/core';
import { LanguageSelectionService } from '@web-desktop/components/menu-top/controllers/language-selection/language-selection.service';
import { RecentItemWidgetUiComponent } from './modules/iupics-widgets/components/recent-item-widget-ui/recent-item-widget-ui.component';
import { GeneratePaymentsWindowAKKComponent } from '@iupics-components/specific/window/generate-payments-window-akk/generate-payments-window-akk.component';
import { GeneratePaymentsWindowAHMComponent } from '@iupics-components/specific/window/generate-payments-window-ahm/generate-payments-window-ahm.component';
import { GeneratePaymentsWindowLBAComponent } from '@iupics-components/specific/window/generate-payments-window-lba/generate-payments-window-lba.component';


@Component({
  selector: 'iu-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  private subscriptions = [];
  globalEnv = Global;
  successMessage: IupicsMessage;
  clearMessageDelay: NodeJS.Timeout;

  constructor(
    private cookieService: IupicsCookieService,
    private languageSelectionService: LanguageSelectionService,
    private translateService: TranslateService,
    @Inject(DOCUMENT) document
  ) {
    if (document.querySelector('.indexLoaderMsg')) {
      (document.querySelector('.indexLoaderMsg') as HTMLElement).style.display = 'none';
    }
    if (document.querySelector('.indexLoader')) {
      (document.querySelector('.indexLoader') as HTMLElement).style.display = 'none';
    }
  }
  ngOnInit() {
    if (
      this.cookieService.checkLS(LocalStorageIupics.last_connected_user) &&
      this.cookieService.checkLS(LocalStorageIupics.access_token)
    ) {
      const userAccount = <UserAccount>(
        JSON.parse(this.cookieService.getDecryptedLocalStorage(LocalStorageIupics.last_connected_user))
      );
      this.languageSelectionService.changeLanguage(userAccount.default_language);
    }

    Global.iupics_components.set('AccordionUiComponent', AccordionUiComponent);
    Global.iupics_components.set('AdditionalInfoUiComponent', AdditionalInfoUiComponent);
    Global.iupics_components.set('AutocompleteUiComponent', AutocompleteUiComponent);
    Global.iupics_components.set('ButtonUiComponent', ButtonUiComponent);
    Global.iupics_components.set('CalendarUiComponent', CalendarUiComponent);
    Global.iupics_components.set('CommentUiComponent', CommentUiComponent);
    Global.iupics_components.set('EditTabUiComponent', EditTabUiComponent);
    Global.iupics_components.set('EditViewUiComponent', EditViewUiComponent);
    Global.iupics_components.set('GridUiComponent', GridUiComponent);
    Global.iupics_components.set('GridViewUiComponent', GridViewUiComponent);
    Global.iupics_components.set('InputFileUiComponent', InputFileUiComponent);
    Global.iupics_components.set('InputImageUiComponent', InputImageUiComponent);
    Global.iupics_components.set('InputLocationUiComponent', InputLocationUiComponent);
    Global.iupics_components.set('InputNumberUiComponent', InputNumberUiComponent);
    Global.iupics_components.set('InputProductAttributesUiComponent', InputProductAttributesUiComponent);
    Global.iupics_components.set('InputSwitchUiComponent', InputSwitchUiComponent);
    Global.iupics_components.set('InputTextareaUiComponent', InputTextareaUiComponent);
    Global.iupics_components.set('InputTextUiComponent', InputTextUiComponent);
    Global.iupics_components.set('InputTimeUiComponent', InputTimeUiComponent);
    Global.iupics_components.set('MenuBarDetailUiComponent', MenuBarDetailUiComponent);
    Global.iupics_components.set('RowUiComponent', RowUiComponent);
    Global.iupics_components.set('SelectOrderComponent', SelectOrderComponent);
    Global.iupics_components.set('StepperUiComponent', StepperUiComponent);
    Global.iupics_components.set('ValuePreferencePanelComponent', ValuePreferencePanelComponent);

    Global.iupics_specific_window.set('default', SpecificWindowUiComponent);
    Global.iupics_specific_window.set('AccountEditorWindowUiComponent', AccountEditorWindowUiComponent);
    Global.iupics_specific_window.set('AccountingViewerUiComponent', AccountingViewerUiComponent);
    Global.iupics_specific_window.set('AccountingViewerWindowUiComponent', AccountingViewerWindowUiComponent);
    Global.iupics_specific_window.set('BpartnerBalanceComponent', BpartnerBalanceComponent);
    Global.iupics_specific_window.set('CreateFromInvoiceWindowUiComponent', CreateFromInvoiceWindowUiComponent);
    Global.iupics_specific_window.set('CreateFromOpenInvoiceComponent', CreateFromOpenInvoiceComponent);
    Global.iupics_specific_window.set('CreateFromShipmentWindowUiComponent', CreateFromShipmentWindowUiComponent);
    Global.iupics_specific_window.set('CreateFromStatementWindowUiComponent', CreateFromStatementWindowUiComponent);
    Global.iupics_specific_window.set('CreateFromOpenInvoiceStWindowUiComponent', CreateFromOpenInvoiceStWindowUiComponent);
    Global.iupics_specific_window.set('CruiseSituationUiComponent', CruiseSituationUiComponent);
    Global.iupics_specific_window.set('FastCreateOrderComponent', FastCreateOrderComponent);
    Global.iupics_specific_window.set('DirectPrintingDocumentsComponent', DirectPrintingDocumentsComponent);
    Global.iupics_specific_window.set('ImportFileLoaderWindowUiComponent', ImportFileLoaderWindowUiComponent);
    Global.iupics_specific_window.set('InvoiceMatchingWindowImplComponent', InvoiceMatchingWindowImplComponent);
    Global.iupics_specific_window.set('PaymentAllocationWindowUiComponent', PaymentAllocationWindowUiComponent);
    Global.iupics_specific_window.set('PaymentSelectionWindowUiComponent', PaymentSelectionWindowUiComponent);
    Global.iupics_specific_window.set('PosUiComponent', PosUiComponent);
    Global.iupics_specific_window.set('PriceHistoryComponent', PriceHistoryComponent);
    Global.iupics_specific_window.set('ProcessInProgressWindowUiComponent', ProcessInProgressWindowUiComponent);
    Global.iupics_specific_window.set('ProductAttributeComponent', ProductAttributeComponent);
    Global.iupics_specific_window.set('SpecificWindowUiComponent', SpecificWindowUiComponent);
    Global.iupics_specific_window.set('OperationManagementUIComponent', OperationManagementUIComponent);
    Global.iupics_specific_window.set('ProductViewComponent', ProductViewComponent);
    Global.iupics_specific_window.set('ChangelogWindowUIComponent', ChangelogWindowUIComponent);
    Global.iupics_specific_window.set('LocationPanelComponent', LocationPanelComponent);
    Global.iupics_specific_window.set('TreeMaintenanceUIComponent', TreeMaintenanceUIComponent);
    Global.iupics_specific_window.set('LoginasFormComponent', LoginasFormComponent);
    Global.iupics_specific_window.set('PlanningWindowUiComponent', PlanningWindowUiComponent);
    
    Global.iupics_widgets.set('RecentItemWidgetUiComponent', RecentItemWidgetUiComponent);
    Global.iupics_specific_window.set('DeliveryModeSelectionUIComponent', DeliveryModeSelectionUIComponent);
    //#region Custo
    Global.iupics_specific_window.set('FastCreateInvoiceVendorComponent', FastCreateInvoiceVendorComponent);
    Global.iupics_specific_window.set('PrintZoneComponent', PrintZoneComponent);
    Global.iupics_specific_window.set('InOutConfirmBatchComponent', InOutConfirmBatchComponent);
    Global.iupics_specific_window.set('ThirdPartyAddressLabelUiComponent', ThirdPartyAddressLabelUiComponent);
    //#endregion Custo

    Global.iupics_specific_window.set('PartnerViewComponent', PartnerViewComponent);
    Global.iupics_specific_window.set('RelatedProductsComponent', RelatedProductsComponent);
    Global.iupics_specific_window.set('GeneratePaymentsWindowAHMComponent', GeneratePaymentsWindowAHMComponent);
    Global.iupics_specific_window.set('GeneratePaymentsWindowAKKComponent', GeneratePaymentsWindowAKKComponent);
    Global.iupics_specific_window.set('GeneratePaymentsWindowLBAComponent', GeneratePaymentsWindowLBAComponent);
    Global.iupics_specific_window.set('GeneratePaymentsWindowAFIComponent', GeneratePaymentsWindowAKKComponent);

  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => {
      sub.unsubscribe();
    });
  }

  @HostListener('document:keydown.shift.alt.delete', ['$event'])
  handleKeyPressed(event: KeyboardEvent) {
    if (event.shiftKey && event.altKey && event.key === 'Delete') {
      this.cookieService.clearLsCookies();
      this.successMessage = new IupicsMessage(
        null,
        this.translateService.instant('homepage.clearLocalStorageCookies'),
        'success'
      );
      this.clearMessageDelay = setTimeout(() => {
        this.closeSuccessMessage();
      }, 1500);
      event.preventDefault();
    }
  }

  closeSuccessMessage() {
    if (this.clearMessageDelay) {
      clearTimeout(this.clearMessageDelay);
    }
    location.reload(true); // charger du server et non pas du cache
    this.successMessage = null;
  }
}
