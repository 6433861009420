import { Component, ComponentFactoryResolver } from '@angular/core';
import { CompiereProcessService } from '@compiere-ws/services/compiere-process/compiere-process.service';
import { ProcessInProgressService } from '@compiere-ws/services/process-in-progress/process-in-progress.service';
import { SocketService } from '@compiere-ws/services/socket/socket.service';
import { SpecificWindowUiComponent } from '@iupics-components/specific/window/specific-window-ui/specific-window-ui.component';
import { DataStoreService } from '@iupics-manager/managers/data-store/data-store.service';
import { SecurityManagerService } from '@iupics-manager/managers/security-manager/security-manager.service';
import { UICreatorService } from '@iupics-manager/managers/ui-creator/ui-creator.service';
import { WindowFactoryService } from '@iupics-manager/managers/ui-creator/window-factory/window-factory.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'iu-generate-payments-window-afi',
  templateUrl: './generate-payments-window-afi.component.html',
  styleUrls: ['./generate-payments-window-afi.component.scss']
})
export class GeneratePaymentsWindowAFIComponent extends SpecificWindowUiComponent {

  constructor(
    windowFactory: WindowFactoryService,
    resolver: ComponentFactoryResolver,
    uiCreator: UICreatorService,
    store: DataStoreService,
    processService: CompiereProcessService,
    protected translateService: TranslateService,
    socketService: SocketService,
    connectorService: SecurityManagerService,
    progressService: ProcessInProgressService
  ) {
    super(
      windowFactory,
      resolver,
      uiCreator,
      store,
      processService,
      socketService,
      connectorService,
      progressService,
      translateService
    );
    this.isModal = false;
  }
 
  notifyFromRowSelected(rowSelected: any): void {
    if (this.dataStore.data['selections'][0].selection.length > 0) {
    {
      this.dataStore.data['Total']=0;
      console.log("GrantTotal #1 ",this.dataStore.data['Total']);
      for (let i = 0; i < this.dataStore.data['selections'][0].selection.length; i++)
      {
        this.dataStore.data['Total']+=this.dataStore.data['selections'][0].selection[i].GrandTotal;
      }
    }
    this.setDataContainersValueWithChangedStore(this.dataStore);
 
   }
}


}
