// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  basehref: '/',
  domain: 'https://samvaz-postgres.dev.apizsolutions.com/',
  authServerUrl: 'auth-server/session/token',
  authServerUrlValid: 'auth-server/session',
  configFilePath: 'iupics-config/default/master',
  clientId: 'ws_api_001',
  clientSecret: 'ws_api_secret',
  production: true,
  appName: 'Apiz Samvaz',
  CUST_version: 'formation_202310',
  STD_version: '2.8.5',
  webSiteUrl: 'https://www.apizsolutions.com/',
  default_language: 'fr_FR'
};
